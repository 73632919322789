<template>
  <c-box>
    <CardStep
      :current="currentStep"
      :total="totalSteps"
      :is-next="currentStep < totalSteps"
      :is-prev="currentStep > 1"
      @next="nextStep"
      @prev="prevStep"
    >
      <c-box v-if="currentStep === 1">
        <c-text font-size="2xl">Identitas</c-text>
        <QuizionaryDetail v-chakra mt="30px" :data="groupedAnswer.identitas" />
      </c-box>
      <c-box v-if="currentStep === 2">
        <c-text font-size="2xl">Antropometri (Ukuran & komposisi tubuh)</c-text>
        <QuizionaryDetail
          v-chakra
          mt="30px"
          :data="groupedAnswer.antropometri"
        />
      </c-box>
      <c-box v-if="currentStep === 3">
        <c-text font-size="2xl">Pola Makan</c-text>
        <QuizionaryDetail v-chakra mt="30px" :data="groupedAnswer.pola_makan" />
      </c-box>
      <c-box v-if="currentStep === 4">
        <c-text font-size="2xl">Perasaan &amp; Pengetahuan</c-text>
        <QuizionaryDetail
          v-chakra
          mt="30px"
          :data="groupedAnswer.perasaan_pengetahuan"
        />
      </c-box>
      <c-box v-if="currentStep === 5">
        <c-text font-size="2xl">Aktivitas Harian</c-text>
        <QuizionaryDetail
          v-chakra
          mt="30px"
          :data="groupedAnswer.aktivitas_harian"
        />
      </c-box>
      <c-box v-if="currentStep === 6">
        <c-text font-size="2xl">Prosedur Medis</c-text>
        <QuizionaryDetail
          v-chakra
          mt="30px"
          :data="groupedAnswer.prosedur_medis"
        />
      </c-box>
    </CardStep>
  </c-box>
</template>

<script >
import { computed, ref, watch } from "@vue/composition-api";
import _ from "lodash";
import CardStep from "@/views/nutritionists/follow-up-notes/card-step.vue";
import QuizionaryDetail from "@/components/quizionary/detail";
import { useRoute } from "@/utils/use-route";
import { useRouter } from "vue2-helpers/vue-router";
const __sfc_main = {};
__sfc_main.props = ["progress"];

__sfc_main.setup = (__props, __ctx) => {
  const $route = useRoute();
  const $router = useRouter();
  const props = __props;
  const progress = computed(() => props.progress);
  let answers = ref([]);
  const groupedAnswer = computed(() => {
    let answer = answers.value.map(item => ({ ...item,
      label: item.question,
      value: getAnswer(item.question, item.answer)
    }));
    return _.groupBy(answer, "category");
  });
  let currentStep = computed({
    get() {
      return +($route.value?.query.step ?? 1);
    },

    set(v) {
      $router.push({
        query: { ...$route.value.query,
          step: v
        }
      });
    }

  });
  const totalSteps = computed(() => Object.keys(groupedAnswer.value).length);
  watch(progress, progress => {
    answers.value = progress?.answers ?? [];
  }, {
    immediate: true
  });

  function getAnswer(question, answer) {
    if (question.toLowerCase().includes("jenis olahraga")) {
      return answer?.map(item => `${item.type}, ${item.duration}`);
    }

    return answer;
  }

  function nextStep() {
    if (currentStep.value < totalSteps.value) {
      currentStep.value++;
    } else {
      console.log("Submit!");
    }
  }

  function prevStep() {
    if (currentStep.value > 1) {
      currentStep.value--;
    }
  }

  return {
    groupedAnswer,
    currentStep,
    totalSteps,
    nextStep,
    prevStep
  };
};

__sfc_main.components = Object.assign({
  CardStep,
  QuizionaryDetail
}, __sfc_main.components);
export default __sfc_main;
</script>
