import { render, staticRenderFns } from "./tab-feedback.vue?vue&type=template&id=153393a5&scoped=true&"
import script from "./tab-feedback.vue?vue&type=script&lang=js&"
export * from "./tab-feedback.vue?vue&type=script&lang=js&"
import style0 from "./tab-feedback.vue?vue&type=style&index=0&id=153393a5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "153393a5",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CFormLabel: require('@chakra-ui/vue').CFormLabel, CInput: require('@chakra-ui/vue').CInput, CFormControl: require('@chakra-ui/vue').CFormControl, CBox: require('@chakra-ui/vue').CBox})
